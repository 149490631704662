import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthToken } from '@npm-libs/auth-manager';
import * as Get from '@npm-libs/ng-getx';
import { ViewComponent, ViewConfig, viewComponentInputs, viewRegistry } from '@npm-libs/ng-templater';
import { AuthService } from '@rcg/auth';
import { RcgPipesModule } from '@rcg/core/pipes';
import { deepDistinctUntilChanged } from '@rcg/standalone/rxjs-operators/deep-distinct-until-changed';
import { combineLatest, debounceTime, map, pairwise, startWith } from 'rxjs';

const name = 'grafana';
type Name = typeof name;

export type GrafanaViewProps = {
  baseUrl: string;
  dashboardId: string;
  orgId: number;
  theme?: string;
};
export type GrafanaViewConfig = ViewConfig<Name, GrafanaViewProps>;

@Get.NgAutoDispose
@Component({
  standalone: true,
  selector: `rcg-templater-view--${name}`,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [...viewComponentInputs],
  imports: [CommonModule, MatIconModule, MatProgressSpinnerModule, RcgPipesModule],
  templateUrl: './grafana.view.component.html',
  styleUrls: ['./grafana.view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrafanaViewComponent extends ViewComponent<Name, GrafanaViewProps, GrafanaViewConfig> implements OnChanges {
  private readonly propsR = new Get.Rx<GrafanaViewProps | null>(null);
  private readonly tokenR = new Get.Rx<AuthToken | undefined>(undefined);

  private readonly urlR = combineLatest([
    this.propsR.value$.pipe(deepDistinctUntilChanged()),
    this.tokenR.value$.pipe(map((t) => t?.rawToken)),
  ])
    .pipe(
      debounceTime(1),
      startWith(null),
      pairwise(),
      map((data) => {
        const [props, token] = data[1]!;

        if (!token) {
          return null;
        }

        const baseUrl = props?.baseUrl;
        const dashboardId = props?.dashboardId;
        const orgId = props?.orgId;

        if (!baseUrl || !dashboardId || !orgId) {
          if (data[0]) console.warn('[GrafanaViewComponent] Invalid props:', { baseUrl, dashboardId, orgId });
          return null;
        }

        return `${baseUrl}/d/${dashboardId}?kiosk&theme=${props.theme ?? 'light'}&orgId=${orgId}&rcg-auth=jwt:${token}`;
      }),
    )
    .obs();

  public readonly props$ = this.propsR.value$;
  public readonly url$ = this.urlR.value$;

  constructor(changeDetectorRef: ChangeDetectorRef, authService: AuthService) {
    super(changeDetectorRef);

    this.tokenR.value$.subscribe((token) => {
      setTimeout(
        async () => {
          this.tokenR.data = await authService.getAuthToken();
        },
        token ? token.expiresAt.getTime() - Date.now() - 5 * 60000 : 0,
      );
    });
  }

  override ngOnChanges(changes: SimpleChanges) {
    if ('config' in changes) this._setProps();
    return super.ngOnChanges(changes);
  }

  private _setProps() {
    const baseUrl = this.config.props?.baseUrl;
    const dashboardId = this.config.props?.dashboardId;
    const orgId = this.config.props?.orgId;

    if (!baseUrl || !dashboardId || !orgId) {
      console.warn(`[${GrafanaViewComponent.name}] Invalid props:`, { baseUrl, dashboardId, orgId });
      this.propsR.data = null;
      return;
    }

    this.propsR.data = this.config.props!;
  }
}

viewRegistry[name] = {
  component: GrafanaViewComponent,
};
